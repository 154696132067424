import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useGetTransaction,
  useResendIpn,
  useUpdateTransaction,
} from "../apiHooks";
import Loader from "components/atoms/Loader";
import Input from "components/atoms/Input";
import { useTrans } from "system/translations/hooks";
import { Pane, ResetIcon, TickIcon, Text } from "evergreen-ui";
import themeOptions from "system/theme";
import Divider from "components/atoms/Divider";
import ActionButtons from "components/atoms/ActionButtons";
import { createStyles } from "types/emotion-styles";
import { css } from "@emotion/react";
import { useDrawer } from "services/drawer/hooks";
import ApproveDecline from "components/atoms/ApproveDecline";
import FormBuilder from "services/formBuilder/FormBuilder";
import { updateTransactionValidation } from "modules/transactions/validation";
import { useUpdateTransactionConfig } from "modules/transactions/constantHooks";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import Btn from "components/atoms/Btn";
import Tabs from "components/atoms/Tabs";
import DescriptionList from "components/molecules/DescriptionList";
import { convertAmount } from "system/helpers/helperFunctions";

const styles = createStyles({
  wrapper: {
    display: "grid",
    height: "100%",
    gridTemplateRows: "1fr 72px",
  },
  container: {
    padding: themeOptions.spacing.md,
  },
  buttonsWrapper: {
    padding: themeOptions.spacing.md,
    borderTop: `1px solid ${themeOptions.colors.gray300}`,
    display: "flex",
    justifyContent: "space-between",
  },
  paymentImageWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px dashed",
    borderColor: themeOptions.colors.gray300,
    padding: themeOptions.spacing.xs,
  },
  paymentImageTitle: {
    fontWeight: 700,
    marginBottom: themeOptions.spacing.xs,
    display: "block",
  },
  paymentImageLink: {
    marginBottom: themeOptions.spacing.xs,
    color: themeOptions.colors.blue400,
    display: "block",
  },
  paymentImage: {
    maxHeight: "200px",
  },
});

const TransactionDetails = ({ transactionId }: { transactionId: string }) => {
  const { _t } = useTrans();
  const { hideDrawer } = useDrawer();
  const [status, setStatus] = useState();
  const [isResend, setResend] = useState(false);
  const { mutateAsync, isLoading: isUpdating } = useUpdateTransaction();
  const { mutateAsync: resendIpn, isLoading: isResending } = useResendIpn();
  const formConfig = useUpdateTransactionConfig({
    isLoading: isUpdating,
    showButtons: false,
    showApproveDecline: true,
  });
  const { data, isLoading } = useGetTransaction(transactionId);
  const [amount, setAmount] = useState("");
  useEffect(() => {
    if (data) {
      setAmount(convertAmount(data?.amount));
    }
  }, [data]);
  const formikRef: any = useRef(null);
  const onSubmit = async () => {
    if (status === "APPROVED") {
      const requestData = {
        data: { status: status, receivedAmount: +amount * 100 },
        id: transactionId.trim(),
      };
      await mutateAsync(requestData);
      hideDrawer();
      return;
    }
    formikRef?.current?.formik?.handleSubmit();
    if (formikRef?.current?.formik?.isValid) {
      const values = formikRef?.current?.formik?.values;
      const requestData = {
        data: { status: status, ...values },
        id: transactionId.trim(),
      };
      mutateAsync(requestData);
      hideDrawer();
    }
  };
  const properties: any = data?.paymentDetails?.properties || {};
  const transactionDetails: any = data?.transactionDetails || {};
  const transactionDetailsList = useMemo(
    () =>
      Object.values(transactionDetails).map((item, idx) => {
        let value = item;
        let key = Object.keys(transactionDetails)[idx];
        if (key.toLowerCase() === "amount") {
          value = convertAmount(value);
        }
        return { title: key, value: value };
      }),
    [properties]
  );
  const paymentDetailsList = useMemo(
    () =>
      Object.values(properties).map((item, idx) => {
        const title = Object.keys(properties)[idx]
        return {
          title,
          value: item,
          withCopyButton: true,
          copyButtonProps: {
            toastTitle: _t("{item}_copied", {
              item: `${_t("payment_details")}: ${title}`
            })
          }
        }
      }),
    [properties]
  );
  const paymentDetailsImages = useMemo(() => {
    return paymentDetailsList.filter((item: any) => {
      if (
        item.value &&
        typeof item.value === "string" &&
        /(.png|.svg|.jpg|.jpeg|.gif)$/g.test(item.value)
      ) {
        return item;
      }
    });
  }, [paymentDetailsList]);
  const tabs = {
    details: {
      name: "details",
      header: _t("transaction_details"),
      headerProps: {},
      bodyProps: { list: transactionDetailsList },
      body: DescriptionList,
      accessTo: "ROLE_VIEW_TRANSACTION_DETAILS",
    },
    info: {
      name: "info",
      header: _t("payment_details"),
      headerProps: {},
      bodyProps: { list: paymentDetailsList },
      body: DescriptionList,
      accessTo: "ROLE_PAYMENT_DETAILS",
    },
  };
  const onResend = async () => {
    data?.transactionUUID && (await resendIpn(data?.transactionUUID));
    await setResend(true);
    setTimeout(() => {
      setResend(false);
    }, 5000);
  };
  const onReset = () => {
    hideDrawer();
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <div css={css(styles.wrapper)}>
      <div css={styles.container}>
        <Pane marginBottom={themeOptions.spacing.sm}>
          <Tabs tabs={tabs} selected={"details"} />
        </Pane>
        <Divider />
        <RoleWrapper accessTo={"ROLE_MANAGE_TRANSACTIONS"}>
          {paymentDetailsImages.map((item: any) => {
            return (
              <div>
                <Text css={css(styles.paymentImageTitle)}>{item.title}</Text>
                <a
                  target={"_blank"}
                  href={item.value}
                  css={css(styles.paymentImageLink)}
                >
                  {item.value}
                </a>
                <div css={css(styles.paymentImageWrap)}>
                  <img src={item.value} css={css(styles.paymentImage)} />
                </div>
              </div>
            );
          })}
          {data?.transactionStatus === "PENDING" && (
            <>
              <Pane>
                <Input
                  label={_t("amount")}
                  onChange={(val) => setAmount(val)}
                  value={amount}
                  style={{ marginBottom: "10px" }}
                />
                <ApproveDecline onChange={setStatus} value={status} />
              </Pane>
              {status === "DECLINED" && (
                <FormBuilder
                  ref={formikRef}
                  formItemsConfig={formConfig}
                  showSubmit={false}
                  formProps={{
                    onSubmit: () => {},
                    onReset: onReset,
                    initialValues: {},
                    validationSchema: updateTransactionValidation(_t),
                  }}
                />
              )}
            </>
          )}
        </RoleWrapper>
        <Pane marginBottom={themeOptions.spacing.sm} />
      </div>
      <div css={styles.buttonsWrapper}>
        <div>
          <RoleWrapper accessTo={"ROLE_RESEND_IPN"}>
            <Btn
              iconBefore={isResend ? TickIcon : ResetIcon}
              kind="linkBorderedBtn"
              label={isResend ? _t("ipn_sent") : _t("resend_ipn")}
              disabled={isResend}
              onClick={onResend}
              isLoading={isResending}
            />
          </RoleWrapper>
        </div>
        {data?.transactionStatus === "PENDING" && (
          <RoleWrapper accessTo={"ROLE_MANAGE_TRANSACTIONS"}>
            <ActionButtons
              onOkKind={"primary"}
              onOk={onSubmit}
              onCancel={hideDrawer}
              isLoading={isUpdating}
              onOkText={_t("save_changes")}
            />
          </RoleWrapper>
        )}
      </div>
    </div>
  );
};

export default TransactionDetails;

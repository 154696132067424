import React from "react";
import { DescriptionListProps } from "./types";
import Description from "../../atoms/Description";
import { DescriptionProps } from "../../atoms/Description/types";
import NoData from "components/atoms/NoData";
import { useTrans } from "system/translations/hooks";

const DescriptionList: React.FC<DescriptionListProps> = ({ list }: any) => {
  const { _t } = useTrans();
  if (!list.length) {
    return <NoData text={_t("no_data")} />;
  }

  return (
    <>
      <div className={"description-list"}>
        {list.map(({title, value, withCopyButton, copyButtonProps }: DescriptionProps) => {
          return (
            <Description
              title={title}
              value={value}
              withCopyButton={title === "transactionId" || withCopyButton}
              copyButtonProps={copyButtonProps}
            />
          );
        })}
      </div>
    </>
  );
};

export default DescriptionList;
